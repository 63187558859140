import Rails from '@rails/ujs';

document.addEventListener('DOMContentLoaded', () => {
  const importLinkElements = document.querySelectorAll('.import-to-editor-link');

  importLinkElements.forEach((importLinkEl) => {
    importLinkEl.addEventListener('ajax:before', () => {
      importLinkEl.classList.add('loading');
      importLinkEl.disabled = true;
    });

    importLinkEl.addEventListener('ajax:success', (event) => {
      const [ data, _status, _xhr ] = event.detail;

      startStatusPollingFor(importLinkEl, data);
    });

    importLinkEl.addEventListener('ajax:error', () => {
      importLinkEl.classList.remove('loading');
      importLinkEl.disabled = false;
    });
  });

  function startStatusPollingFor(importLinkEl, { status_path: statusPath }) {
    let statusPollingInterval = setInterval(function () {
      fetch(statusPath, { headers: { 'X-CSRF-Token': Rails.csrfToken() } })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === 'pending') return;

          clearInterval(statusPollingInterval);
          statusPollingInterval = null;

          importLinkEl.classList.remove('loading');
          importLinkEl.disabled = false;

          if (response.status === 'failed') {
            alert(':( Something went wrong and we couldn\'t import your story. Please close the modal and try again.');
            return;
          };

          window.location.href = response.bookshelf_import_url;
        });
    }, 3_000);
  };
});
