document.addEventListener('DOMContentLoaded', function() {
  const _setElementVisible = (elementId) => {
    const element = document.getElementById(elementId);
    if (!element) {
      return;
    }

    element.classList.add('visible');
  };

  const triggerSignupModalEls = document.querySelectorAll('.trigger-signup-modal');
  triggerSignupModalEls.forEach((triggerSignupModalEl) => {
    triggerSignupModalEl.addEventListener('click', () => {
      _setElementVisible('prompt-signup-modal');
    });
  });

  function _collapsableClick(ev) {
    const id = ev.target.dataset.commentNestId;
    document.getElementById(`comment-nest-${ id }`).classList.toggle('fade-in');
    document.getElementById(`comment-show-replies-${ id }`).classList.toggle('fade-in');
  }

  // Comments
  document.querySelectorAll('.collapsable').forEach((collapsableEl) => {
    collapsableEl.addEventListener('click', _collapsableClick);
  });

  document.querySelectorAll('.show-replies').forEach((repliesEl) => {
    repliesEl.addEventListener('click', _collapsableClick);
  });

  // Modals
  const modalTriggerEls = document.querySelectorAll('[data-modal-trigger]');
  modalTriggerEls.forEach((modalEl) => {
    const id = modalEl.dataset.modalTrigger;
    const modal = document.getElementById(id)

    if (!modal) {
      return;
    }
    modalEl.addEventListener('click', function() {
      modal.classList.add('visible');
    });
  });

  // Quill
  var defaults = {
    theme: 'snow',
    modules: {
        toolbar: [
          [{ 'header': 1 }, { 'header': 2 }],
          ['bold', 'italic', 'underline', 'strike'],
          ['clean']
        ]
      }
    };
  //This is the global config object
  Quilljs.setDefaults(defaults);

  if (!!window['quill-container-0']) {
    window['quill-container-0'].clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
      delta.ops = delta.ops.map(function (op) {
        if (op.attributes) {
          delete op.attributes.background;
          delete op.attributes.color;
        }
        return op;
      });
      return delta;
    });
  }
});
