const TRIGGER_POINT = 84;

document.addEventListener('DOMContentLoaded', () => {
  const headerEl = document.querySelector('header');

  if (!headerEl || headerEl.classList.contains('disable-scroll')) {
    return;
  }

  const _scrollPerformed = () => {
    const shouldFix = (document.scrollingElement.scrollTop > TRIGGER_POINT);
    headerEl.classList.toggle('nav-fixed', shouldFix);
  };

  window.addEventListener('scroll', _scrollPerformed);

  // Burger nav
  const burgerMenuEl = document.querySelector('.nav-tray .burger');
  const navMobileEl = document.querySelector('.nav-mobile');
  const navCloseEl = document.querySelector('.nav-close');

  if (!burgerMenuEl || !navMobileEl) {
    return;
  }

  burgerMenuEl.addEventListener('click', () => {
    navMobileEl.classList.add('nav-active');
  });

  navCloseEl.addEventListener('click', () => {
    navMobileEl.classList.remove('nav-active');
  });
});
