const JS_SRC = 'https://www.google.com/recaptcha/enterprise.js?render=<RECAPTCHA_V3_KEY>&waf=session';

function _addTokenToInputs(token) {
  const captchaFormInputEls = document.querySelectorAll('.g-recaptcha-response');
  captchaFormInputEls.forEach((captchaFormInputEl) => {
    captchaFormInputEl.value = token;
  });
}

function _attachToDom(recaptchaV3Key) {
  const scriptEl = document.createElement('script');
  scriptEl.src = JS_SRC.replace('<RECAPTCHA_V3_KEY>', recaptchaV3Key);
  document.body.appendChild(scriptEl);
}

class CaptchaHandler {
  constructor(recaptchaV3Key) {
    if (!recaptchaV3Key || this._isAttached === true) {
      return;
    }

    _attachToDom(recaptchaV3Key);
    this._isAttached = true;
    this._isV2Rendered = false;

    this.setupCaptchaForms(recaptchaV3Key);
  }

  setupCaptchaForms(recaptchaV3Key) {
    const captchaSignupFormEls = document.querySelectorAll('form.captcha-signup');
    if (!captchaSignupFormEls.length) {
      return;
    }

    captchaSignupFormEls.forEach((captchaSignupFormEl) => {
      captchaSignupFormEl.addEventListener('submit', (ev) => {
        ev.preventDefault();

        grecaptcha.enterprise
          .execute(recaptchaV3Key, {
            action: 'prompts_signup',
          })
          .then((token) => {
            _addTokenToInputs(token);
            captchaSignupFormEl.submit();
          });
      });
    });
  }

  static setupEmailInputs() {
    const emailInputEls = document.querySelectorAll('input[type="email"');
    if (!emailInputEls.length) {
      return;
    }

    const _setGRecaptchaOpacity = (value) => {
      const gRecaptchaBadgeEl = document.querySelector('.grecaptcha-badge');
      if (!gRecaptchaBadgeEl) {
        return;
      }

      gRecaptchaBadgeEl.style.opacity = value;
    };

    emailInputEls.forEach((emailInputEl) => {
      emailInputEl.addEventListener('focus', () => {
        _setGRecaptchaOpacity('1');
      });

      emailInputEl.addEventListener('blur', () => {
        _setGRecaptchaOpacity('0');
      });
    });
  }
}

window.Reedsy = window.Reedsy || {};
window.Reedsy.CaptchaHandler = CaptchaHandler;

document.addEventListener('DOMContentLoaded', () => {
  CaptchaHandler.setupEmailInputs();
});
