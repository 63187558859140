document.addEventListener('DOMContentLoaded', () => {
  const form = document.getElementById("payment-form");

  if (!form) {
    return;
  }

  var stripe = Stripe(process.env.STRIPE_PUBLISHABLE_KEY);

  // Disable the button until we have Stripe set up on the page
  document.querySelector("button").disabled = true;

  var elements = stripe.elements();

  var style = {
    base: {
      color: "#32325d",
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d"
      }
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  };

  var card = elements.create(
    "card",
    {
      hidePostalCode: true,
      style: style
    }
  );
  // Stripe injects an iframe into the DOM
  card.mount("#card-element");

  card.on("change", function (event) {
    document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
  });

  function updatePaymentButton() {
    form.querySelector("button").disabled = form.querySelector(':invalid');
  };

  const inputs = form.getElementsByTagName("input");
  for (let input of inputs) {
    input.addEventListener('input', updatePaymentButton);
  }

  const clientSecret = document.getElementById('client-secret').dataset.attrs;

  form.addEventListener("submit", function(event) {
    event.preventDefault();

    // Complete payment when the submit button is clicked
    payWithCard(stripe, card, clientSecret);
  });

  // Calls stripe.confirmCardPayment
  // If the card requires authentication Stripe shows a pop-up modal to
  // prompt the user to enter authentication details without leaving your page.
  var payWithCard = function(stripe, card, clientSecret) {
    loading(true);
    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: card,
          billing_details: {
            name: document.getElementById("name").value,
          },
        }
      })
      .then(function(result) {
        if (result.error) {
          // Show error to your customer
          showError(result.error.message);
        } else {
          // The payment succeeded!
          orderComplete();
        }
      });
  };

  /* ------- UI helpers ------- */

  // Shows a success message when the payment is complete
  var orderComplete = function() {
    loading(false);
    document.querySelector(".result-message").classList.remove("hidden");
    document.querySelector("button").disabled = true;
    document.getElementById("confirm-payment-form").submit();
  };

  // Show the customer the error from Stripe if their card fails to charge
  var showError = function(errorMsgText) {
    loading(false);
    var errorMsg = document.querySelector("#card-error");
    errorMsg.textContent = errorMsgText;
    setTimeout(function() {
      errorMsg.textContent = "";
    }, 4000);
  };

  // Show a spinner on payment submission
  var loading = function(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector("button").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } else {
      document.querySelector("button").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  };

  // Apple pay

  const amount = parseInt(document.getElementById('amount').dataset.attrs);

  var paymentRequest = stripe.paymentRequest({
    country: 'US',
    currency: 'usd',
    total: {
      label: 'Submission',
      amount: amount,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  });

  var prButton = elements.create('paymentRequestButton', {
    paymentRequest: paymentRequest,
  });

  // Check the availability of the Payment Request API first.
  paymentRequest.canMakePayment().then(function(result) {
    if (result) {
      prButton.mount('#payment-request-button');
    } else {
      document.getElementById('payment-request-button').style.display = 'none';
    }
  });

  paymentRequest.on('paymentmethod', function(ev) {
    // Confirm the PaymentIntent without handling potential next actions (yet).
    stripe.confirmCardPayment(
      clientSecret,
      {payment_method: ev.paymentMethod.id},
      {handleActions: false}
    ).then(function(confirmResult) {
      if (confirmResult.error) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (confirmResult.paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          stripe.confirmCardPayment(clientSecret).then(function(result) {
            if (result.error) {
              showError(result.error.message);
            } else {
              // The payment succeeded!
              orderComplete();
            }
          });
        } else {
          // The payment has succeeded.
          orderComplete();
        }
      }
    });
  });

});

