import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById("pool_container")

  if (container) {
    consumer.subscriptions.create("SubmissionStateChannel", {
      connected() {
        container.classList.add("connected");
      },

      disconnected() {
        const container = document.getElementById("pool_container")
        container.classList.remove("connected");
      },

      received(data) {
        // Fetch submission ID
        const submission_id = 'submission_' + data["submission_id"]
        // Fetch submission
        const submission = document.getElementById(submission_id)

        // Animate submission out of list if present
        if (submission != null) {
          submission.classList.add("removed");
          setTimeout(function() {
            submission.remove()
          }, 1000)
        }

        // Update submission counter
        const submission_counter = document.getElementById("submission_counter")
        const count = submission_counter.innerHTML - 1
        submission_counter.innerHTML = count
      }
    });
  }
});
