import Choices from "choices.js"
import "choices.js/public/assets/styles/choices.min.css"

document.addEventListener('DOMContentLoaded', () => {

  const maxSubmissions = 3;

  function _checkCount() {
    const currentValues = categoryChoices.getValue(true);

    if (currentValues.length === 0) {
      choicesInputEl.placeholder = 'Please select a category';
    } else {
      choicesInputEl.placeholder = '';
    }

    if (currentValues.length >= maxSubmissions) {
      choicesInputEl.style.display = 'none';
    } else {
      choicesInputEl.style.display = 'inline-block';
    }
  }

  const choicesElement = document.getElementById('submission_category_ids');

  if (!choicesElement) {
    return
  }

  const categoryChoices = new Choices(choicesElement, {
    duplicateItemsAllowed: false,
    maxItemCount: maxSubmissions,
    maxItemText: (maxItemCount) => {
      return `Only ${ maxItemCount } categories can be selected.`;
    },
    paste: false,
    removeItemButton: true,
  });

  choicesElement.addEventListener('change', _checkCount);
  const choicesInputEl = document.querySelector('input.choices__input');
});