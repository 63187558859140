// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels/index"

import EmailCheckerHelper from '../src/application/helpers/email-checker-helper/email-checker-helper';

// lazysizes
import "lazysizes";

// quill
import Quill from "quill"
import "quill/dist/quill.snow.css"
import "../src/application/quill.global"
window.Quill = Quill

// reedsy-modals
import '@reedsy/reedsy-modals/dist/reedsy-modals.lite.js';
import '@reedsy/reedsy-modals/dist/reedsy-modals.lite.css';

// canvas-confetti
import confetti from "canvas-confetti"
window.confetti = confetti

Rails.start()
ActiveStorage.start()

import "../src/application/captcha"
import "../src/application/choices"
import "../src/application/nav"
import "../src/application/ios-detector"
import "../src/application/modals"
import "../src/application/prompts"
import "../src/application/stripe"
import "../src/application/countdown"
import "../src/application/submission"

import "../stylesheets/application.scss";

import '@reedsy/email-checker';
import '@reedsy/email-checker/dist/reedsy-email-checker.css';

require.context("../media/images/", true);
require.context("../media/fonts/", true);

document.addEventListener('DOMContentLoaded', () => {
  EmailCheckerHelper.setup();
});