// Landing UI modals

document.addEventListener('DOMContentLoaded', () => {
  const _hideModal = () => {
    const visibleModals = document.querySelectorAll('.modal.visible');

    visibleModals.forEach((visibleModal) => {
      visibleModal.classList.remove('visible');
    });
  };

  // Close modal with ( x ) button
  const buttonCloseEls = document.querySelectorAll('.btn-close');
  buttonCloseEls.forEach((buttonCloseEl) => {
    buttonCloseEl.addEventListener('click', _hideModal);
  });

  // Close the modal via an arbitrary element
  const arbitraryCloseEls = document.querySelectorAll('.modal-close');
  arbitraryCloseEls.forEach((arbitraryCloseEl) => {
    arbitraryCloseEl.addEventListener('click', _hideModal);
  });

  // Close modal via overlay
  const modalOverlayEls = document.querySelectorAll('.modal-overlay');
  modalOverlayEls.forEach((modalOverlayEl) => {
    if (modalOverlayEl.classList.contains('locked')) {
      return;
    }
    modalOverlayEl.addEventListener('click', _hideModal);
  });
});
